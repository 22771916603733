import { onBeforeUnmount, ref } from 'vue';

export default function useCurrentTime() {
  const currentTime = ref(Date.now());
  const currentTimeInterval = ref(
    window.setInterval(() => {
      currentTime.value = Date.now();
    }, 2000),
  );
  onBeforeUnmount(() => window.clearInterval(currentTimeInterval.value));
  return {
    currentTime,
  };
}
